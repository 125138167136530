import React from "react";
import { Link } from "react-router-dom";
import { SocialIconEmailPng, SocialIconFacebookIconPng, SocialIconInstagramPng, SocialIconLinkedinPng, SocialIconTwitterIconPng, SocialIconYoutubeIconPng } from "../../assets/images/socialIcons";
import "../../styles/socialShare.scss";
import { playHoverSound } from "../../utils/hover-sound";

const SharingIcons = ({
  youtube = "https://www.youtube.com/channel/UCvISE7GgoP8-x5WZzECSwvw",
  instagram = "https://www.instagram.com/joshuwab_/",
  twitter = "https://twitter.com/brettaljeni",
  facebook = "https://www.facebook.com/josh.brett.1042",
  linkedin = "https://www.linkedin.com/in/joshua-brett-0274a1195/",
  email = "",
  display = { youtube: false, twitter: false, instagram: false, email: false, facebook: false, linkedin: false },
  classNames = "",
}) => {
  return (
    <React.Fragment>
      <div className={`social-share-page ${classNames}`}>
        <div className="wrapper">
          <ul>
            {display.youtube ? (
              <li className="facebook" onMouseEnter={() => playHoverSound()}>
                <a href={youtube} target="_blank" rel="noreferrer">
                  <img src={SocialIconYoutubeIconPng} alt="" width={"100px"} height="100px" />
                </a>
              </li>
            ) : null}

            {display.instagram ? (
              <li className="twitter" onMouseEnter={() => playHoverSound()}>
                <a href={instagram} target="_blank" rel="noreferrer">
                  <img src={SocialIconInstagramPng} alt="" width={"100px"} height="100px" />
                </a>
              </li>
            ) : null}
            {display.linkedin ? (
              <li className="youtube" onMouseEnter={() => playHoverSound()}>
                <a href={linkedin} target="_blank" rel="noreferrer">
                  <img src={SocialIconLinkedinPng} alt="" width={"100px"} height="100px" />
                </a>
              </li>
            ) : null}
            {display.email ? (
              email ? (
                <li className="whatsapp" onMouseEnter={() => playHoverSound()}>
                  <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
                    <img src={SocialIconEmailPng} alt="" width={"100px"} height="100px" />
                  </a>
                </li>
              ) : (
                <li className="whatsapp" onMouseEnter={() => playHoverSound()}>
                  <Link to={"/contact-us"}>
                    {" "}
                    <img src={SocialIconEmailPng} alt="" width={"100px"} height="100px" />
                  </Link>
                </li>
              )
            ) : null}
            {display.twitter ? (
              <li className="whatsapp" onMouseEnter={() => playHoverSound()}>
                <a href={twitter} target="_blank" rel="noreferrer">
                  <img src={SocialIconTwitterIconPng} alt="" width={"100px"} height="100px" />
                </a>
              </li>
            ) : null}
            {display.facebook ? (
              <li className="whatsapp" onMouseEnter={() => playHoverSound()}>
                <a href={facebook} target="_blank" rel="noreferrer">
                  {" "}
                  <img src={SocialIconFacebookIconPng} alt="" width={"100px"} height="100px" />
                </a>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SharingIcons;

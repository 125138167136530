// animation
// @ts-ignore
import AOS from "aos";
import "aos/dist/aos.css";
import React, { Suspense, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import Footer from "./components/Footer";
import NeonLoader from "./components/global/NeonLoader";
import Header from "./components/Header";
import Routing from "./routes/Routing";
import "./styles/global.scss";
import "./styles/starryNight.scss";
import useScrollToTopOnRouteChange from "./utils/hooks/useScrollToTopOnRouteChange";
// import "./styles/glitchAnimation.scss";
// import { glitch } from "./utils/animation";

function App() {
  useEffect(() => {
    // init animation only after pag eload is finish
    window.addEventListener("load", (event) => {
      if (document.readyState === "complete") {
        AOS.init({
          duration: 1200,
        });
      }
      // let height = document.getElementsByTagName("body")[0]?.offsetHeight;
      // let bg = document.getElementById("starryNight");
      // if (bg) {
      //   bg.style.height = `${height}px`;
      // }
    });
  }, []);

  useScrollToTopOnRouteChange();
  return (
    <div className="App min-h-screen h-full p-[23px] relative z-10">
      <HelmetProvider>
        <Suspense fallback={<NeonLoader />}>
          <Header />
          <Routing />
          <Footer />
        </Suspense>
      </HelmetProvider>
    </div>
  );
}

export default App;

export const joshLogoHoverBg = (
  <svg width="152" height="129" viewBox="0 0 152 129" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 1H1V15" stroke="#DDDDDD" strokeWidth="2" />
    <path d="M16 128H1V114" stroke="#DDDDDD" strokeWidth="2" />
    <path d="M136 128H151V114" stroke="#DDDDDD" strokeWidth="2" />
    <path d="M136 1H151V15" stroke="#DDDDDD" strokeWidth="2" />
    <g filter="url(#filter0_b_11_226)">
      <rect x="7" y="4" width="141" height="121" fill="#C4C4C4" fillOpacity="0.14" />
    </g>
    <line x1="3.68765" y1="8.60957" x2="8.68765" y2="4.60957" stroke="white" />
    <line x1="148.312" y1="121.39" x2="143.312" y2="125.39" stroke="white" />
    <defs>
      <filter id="filter0_b_11_226" x="-3" y="-6" width="161" height="141" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_11_226" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_11_226" result="shape" />
      </filter>
    </defs>
  </svg>
);

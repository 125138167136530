import { useEffect, useState } from "react";
import { apiGetFooterSocialLinks } from "../api/controllers/get-footer-socials";
import { ISocialAttributes } from "../api/types";
import SharingIcons from "./global/SharingIcons";

const Footer = () => {
  const [socialProfiles, setSocialProfiles] = useState<ISocialAttributes | undefined>();
  useEffect(() => {
    apiGetFooterSocialLinks()
      .then((response) => {
        setSocialProfiles(response.data?.[0]?.attributes);
      })
      .catch((error) => {
        // handle error
      });
  }, []);

  return (
    <SharingIcons
      youtube={socialProfiles?.YouTube}
      twitter={socialProfiles?.Twitter}
      instagram={socialProfiles?.Instagram}
      facebook={socialProfiles?.facebook}
      linkedin={socialProfiles?.LinkedIn}
      email={socialProfiles?.Email}
      display={{
        youtube: Boolean(socialProfiles?.YouTube),
        twitter: Boolean(socialProfiles?.Twitter),
        instagram: Boolean(socialProfiles?.Instagram),
        email: Boolean(socialProfiles?.Email),
        facebook: Boolean(false),
        linkedin: Boolean(socialProfiles?.LinkedIn),
      }}
      classNames="!mt-[66px]"
    />
  );
};

export default Footer;

import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { hamBottomBar, hamMiddleBar, hamTopBar } from "../assets/images/hamburger";
// import joshLogoBackground from "../assets/images/josh-logo-hover-bg.svg";
import joshLogo from "../assets/images/josh-logo.png";
import { joshLogoShakeCompressedTransparent } from "../assets/images/logo-glitch";
import { joshLogoHoverBg } from "../assets/svg/JoshLogoHoverBg";
import { AppRoutes } from "../shared/constant";
import "../styles/css/header.css";
import "../styles/header.scss";

const Header = () => {
  const navigate = useNavigate();
  const [glitch, setGlitch] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    var lastScrollTop = 0;
    window.addEventListener("scroll", (e: any) => {
      let currentScrollTopPosition = e.target.documentElement.scrollTop;

      // checking if user is scrolling to top or bottom
      if (currentScrollTopPosition > lastScrollTop) {
        headerRef.current?.classList.remove("active");
        headerRef.current?.classList.add("bottom");
      } else {
        headerRef.current?.classList.add("active");
        headerRef.current?.classList.remove("bottom");
      }

      // store the last scroll position of user
      lastScrollTop = currentScrollTopPosition <= 0 ? 0 : currentScrollTopPosition;
    });
  }, []);

  // reset bg height on something change or page change
  // setting it on route change was not working
  let height = document.getElementsByTagName("body")[0]?.offsetHeight;
  let bg = document.getElementById("starryNight");
  if (bg) {
    bg.style.height = `${height}px`;
  }

  const location = useLocation();

  return (
    <React.Fragment>
      <header className="header" ref={headerRef}>
        <div className="group relative z-50" onMouseEnter={() => setGlitch(true)} onMouseLeave={() => setGlitch(false)} data-aos="zoom-in-right" onClick={() => navigate("/")}>
          <img src={glitch ? joshLogoShakeCompressedTransparent : joshLogo} alt="main logo" className="logo-img" />
          <div className="logo-bg group-hover:block">{joshLogoHoverBg}</div>
          {/* <img src={joshLogoBackground} alt="main logo" className="h-[120px] cursor-pointer absolute z-0 top-0 bottom-0 right-0 left-0 group-hover:block hidden transition-all" /> */}
        </div>
        <div data-aos="zoom-in-left">
          <div className={`hamburger ${isMenuActive ? "is-active" : ""} cursor-pointer z-50 relative`} onClick={() => setIsMenuActive(!isMenuActive)}>
            <img src={hamTopBar} alt="top line" className="w-[63px] h-[5px] top" />
            <img src={hamMiddleBar} alt="middle line" className="w-[80px] h-[6px] mt-4 middle" />
            <img src={hamBottomBar} alt={"bottom line"} className="w-[63px] h-[5px] mt-4 bottom" />
            <ol className={`sub-menu items-start justify-start flex-col ${isMenuActive ? "flex" : ""}`}>
              {Object.entries(AppRoutes).map(([key, value], index) => {
                return (
                  <Link to={value.route} key={value.title} className="w-full">
                    <li
                      className={"menu-item w-full"}
                      style={{ order: value.position }}
                      onClick={() => {
                        if (value.title === "About Us") {
                          location.pathname === "/"
                            ? document.getElementById("about-us")?.scrollIntoView()
                            : setTimeout(() => {
                                document.getElementById("about-us")?.scrollIntoView();
                              }, 1000);
                        }
                      }}>
                      {value.title}
                    </li>
                  </Link>
                );
              })}
            </ol>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;

export const AppRoutes = {
  home: {
    title: "Home",
    route: "/",
    position: 0,
  },
  videos: {
    title: "Videos",
    route: "videos",
    position: 1,
  },
  about: {
    title: "About Us",
    route: "",
    position: 2,
  },
  team: {
    title: "Our Team",
    route: "our-team",
    position: 3,
  },
  faq: {
    title: "FAQ",
    route: "faq",
    position: 4,
  },
  careers: {
    title: "Join Us",
    route: "careers",
    position: 5,
  },
  contact: {
    title: "Contact Us",
    route: "contact-us",
    position: 6,
  },
};

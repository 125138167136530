import HTTPService from "../../services/httpService";
import { API_ENDPOINTS } from "../api-endpoints";
import { ISocialProfileResponse, Meta } from "../types";

export const apiGetFooterSocialLinks = (): Promise<ITeamMembersResponse> => {
  return HTTPService.get(API_ENDPOINTS.socials + "?filters[name]=josh");
};

export interface ITeamMembersResponse extends ISocialProfileResponse {
  meta: Meta;
}

import React from "react";
import { useRoutes } from "react-router-dom";
import { PublicRoutesObject } from "./Routes";

const PublicRoutes = () => {
  let RenderPublicRoutes = useRoutes(PublicRoutesObject);
  return <React.Fragment>{RenderPublicRoutes}</React.Fragment>;
};

export default PublicRoutes;

import React from "react";
import "../../styles/neonLoader.scss";

const NeonLoader = () => {
  return (
    <div className="neon-loader-container top-0">
      <div className="grid-big">
        <div className="cell-1"> </div>
        <div className="cell-1"> </div>
        <div className="cell-1"> </div>
        <div className="cell-1"> </div>
        <div className="cell-1"> </div>
        <div className="cell-1"> </div>
        <div className="cell-1"> </div>
        <div className="cell-1"> </div>
        <div className="cell-1"> </div>
        <div className="cell-1"> </div>
        <div className="cell-1"> </div>
        <div className="cell-1"> </div>
        <div className="cell-1"> </div>
        <div className="cell-1"> </div>
        <div className="cell-1"> </div>
        <div className="cell-1"> </div>
        <div className="cell-1"> </div>
        <div className="cell-1"> </div>
        <div className="cell-1"> </div>
        <div className="cell-1"> </div>
        <div className="cell-1"> </div>
        <div className="cell-1"> </div>
        <div className="cell-1"> </div>
        <div className="cell-1"> </div>
        <div className="cell-1"> </div>
      </div>
    </div>
  );
};

export default NeonLoader;

import axios, { AxiosResponse } from "axios";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_API_URL}/api/`,
});

const errorInterceptor = (errorResponse: AxiosResponse) => {
  if (errorResponse) {
    const { status } = errorResponse.data;
    if (status === 401) {
      console.log("user is unauthorized");
    }
  }
};

axiosInstance.interceptors.request.use(
  (req) => {
    const cookie = { token: "", user: "" };
    // check for token
    if (cookie && cookie.token) {
      // @ts-ignore
      req.headers.Authorization = cookie.token;
    }
    return req;
  },
  (err) => {
    // catches client side error like no internet etc
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(
  (req) => {
    // setisLoaderActive(false);
    return req;
  },
  (err) => {
    errorInterceptor(err.response);
    return Promise.reject(err);
  }
);

export default class HTTPService {
  static get<T = never, R = AxiosResponse<T>>(url: string, params?: any): Promise<R> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(url, params)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }

  static put<T = never, R = AxiosResponse<T>>(url: string, body: any): Promise<R> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(url, body)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }

  static post<T = never, R = AxiosResponse<T>>(url: string, body: any): Promise<R> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(url, body)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }

  static delete<T = never, R = AxiosResponse<T>>(url: string, body: any): Promise<R> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(url, { data: body })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }

  static deleteWithParams<T = never, R = AxiosResponse<T>>(url: string, params: any): Promise<R> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(url, params)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }
}

import React from "react";
import { Outlet, RouteObject } from "react-router-dom";
import { AppRoutes } from "../shared/constant";

const AboutUs = React.lazy(() => import("../pages/AboutUs"));
const Careers = React.lazy(() => import("../pages/Careers"));
const Contact = React.lazy(() => import("../pages/Contact"));
const FAQ = React.lazy(() => import("../pages/FAQ"));
const Home = React.lazy(() => import("../pages/Home"));
const OurTeam = React.lazy(() => import("../pages/OurTeam"));
const SingleVideo = React.lazy(() => import("../pages/SingleVideo"));
const Videos = React.lazy(() => import("../pages/Videos"));
const PageNotFound = React.lazy(() => import("../pages/404"));

export const PublicRoutesObject: RouteObject[] = [
  {
    path: "/",
    element: <Outlet />,
    children: [
      { index: true, element: <Home /> },
      { path: AppRoutes.contact.route, element: <Contact /> },
      { path: AppRoutes.about.route, element: <AboutUs /> },
      { path: AppRoutes.careers.route, element: <Careers /> },
      { path: AppRoutes.team.route, element: <OurTeam /> },
      {
        path: AppRoutes.videos.route,
        element: <Outlet />,
        children: [
          { index: true, element: <Videos /> },
          {
            path: ":videoId",
            element: <SingleVideo />,
          },
        ],
      },
      { path: AppRoutes.faq.route, element: <FAQ /> },
      { path: "/404", element: <PageNotFound /> },
      { path: "*", element: <PageNotFound /> },
    ],
  },
];

export const ProtectedRoutesObject: RouteObject[] = [];
